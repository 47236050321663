/* flatpickr is broken when you enable time.  This is a temp fix. */
.flatpickr-time {
  height: auto;
}

.grecaptcha-badge {
  visibility: hidden;
}
/* google autocomplete on top of modals */
.pac-container {
  z-index: 99999 !important;
}

/* SFDC Chat */

.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #29afff;
  font-family: 'Salesforce Sans', sans-serif;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #29afff;
}

@font-face {
  font-family: 'Salesforce Sans';
  src: url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.woff')
      format('woff'),
    url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.ttf')
      format('truetype');
}
