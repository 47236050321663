#graphiql-wrapper *,
#graphiql-wrapper *::before,
#graphiql-wrapper *::after {
  box-sizing: content-box !important;
}

#graphiql-wrapper .graphiql-container {
  min-height: 100vh;
  width: 100%;
}
